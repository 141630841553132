import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import not from 'lodash/fp/negate';

import useStoreState from 'hooks/useStoreState';

import LoadingScreen from 'containers/LoadingScreen/LoadingScreen';
import Footer from 'components/Footer/Footer';
import NavBar from 'components/NavBar';
import NotFound from 'components/NotFound/NotFound';
import makeRouteGuard from 'utils/makeRouteGuard';
import { GlobalStore, OrganizationModules } from 'types/store';
import and from 'utils/and';

import newUserRoutes from 'containers/Routes/newUserRoutes';
import devRoutes from 'containers/Routes/devRoutes';
import subscriberRoutes from 'containers/Routes/subscriberRoutes';
import loginRoutes from 'containers/Routes/loginRoutes';
import adminRoutes from 'containers/Routes/adminRoutes';
import bizDevRoutes from 'containers/Routes/bizDevRoutes';

const useClasses = makeStyles({
  app_route_container: {
    display: 'flex',
    flexFlow: 'column',
    minHeight: '100vh',
    width: '100%',
    overflow: 'auto',
  },
  app_data_view: {
    flexGrow: 2,
  },
});

const isInitialized = ({ initialized }: GlobalStore): boolean => initialized;
const isLoggedOut = ({ user }: GlobalStore): boolean => user.isLoggedIn === false;
const isLoggedIn = ({ user }: GlobalStore): boolean => user.isLoggedIn === true;
const isDev = ({ user }: GlobalStore): boolean => user.isDeveloper === true;
const isAdmin = ({ user }: GlobalStore): boolean => user.isAdmin === true || user.isManager === true || user.isDeveloper === true;
const isBizDev = ({ user }: GlobalStore): boolean => user.isBizDev === true || user.isDeveloper === true;
const isNewUser = ({ user }: GlobalStore): boolean => Boolean(user.authDetails && user.authDetails.isNewUser);

const useNewUserRoutes = makeRouteGuard(and(isNewUser)(isInitialized), newUserRoutes);
const useDevRoutes = makeRouteGuard(and(isDev)(isInitialized), devRoutes);
const useSubscriberRoutes = makeRouteGuard(and(isLoggedIn)(isInitialized), subscriberRoutes);
const useLoginRoutes = makeRouteGuard(isLoggedOut, loginRoutes);
const useAdminRoutes = makeRouteGuard(and(isAdmin)(isInitialized), adminRoutes);
const useBizDevRoutes = makeRouteGuard(and(isBizDev)(isInitialized), bizDevRoutes);
const useLoadingRoute = makeRouteGuard(not(isInitialized), (modules: OrganizationModules) => ({
  '/': LoadingScreen,
}));

const Routes = () => {
  const { app_route_container, app_data_view } = useClasses();
  const newUserRoutes = useNewUserRoutes();
  const devRoutes = useDevRoutes();
  const subscriberRoutes = useSubscriberRoutes();
  const loginRoutes = useLoginRoutes();
  const loadingRoutes = useLoadingRoute();
  const adminRoutes = useAdminRoutes();
  const bdRoutes = useBizDevRoutes();
  const initialized = useStoreState(({ initialized }) => initialized);

  return (
    <React.Suspense fallback={<LoadingScreen />}>
      <div className={app_route_container}>
        <div className={app_data_view}>
          {initialized && (
            <NavBar key='nav-bar'>
              <React.Suspense fallback={<LoadingScreen />}>
                <Switch>
                  {bdRoutes}
                  {newUserRoutes}
                  {devRoutes}
                  {adminRoutes}
                  {subscriberRoutes}
                  {loginRoutes}
                  {loadingRoutes}
                  <Route path='*' component={NotFound} />
                </Switch>
              </React.Suspense>
            </NavBar>
          )}
        </div>
        {initialized && <Footer />}
      </div>
    </React.Suspense>
  );
};

export default Routes;
