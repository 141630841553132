import { getPlatforms } from '@ionic/react';

export const isMobile = (): boolean => {
  const platform = getPlatforms();
  return (
    (platform.includes('android') || platform.includes('ios')) &&
    !platform.includes('mobileweb')
  );
};

export default isMobile;
