/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  links: {
    color: theme.palette.common.white,
  },
}));

const LoginLink = ({ onClick, name, className }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Typography className={className}>
      <Link
        component="button"
        variant="caption"
        display="block"
        className={classes.links}
        onClick={onClick}
      >
        {name}
      </Link>
    </Typography>
  );
};

LoginLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

LoginLink.defaultProps = {
  className: '',
};

export default LoginLink;
