interface Window {
  setItem: Function;
  removeItem: Function;
  length: Number;
}

export const storageAvailable = (type: string): Boolean => {
  let storage: Window = (window as any)[type];
  try {
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
};

export const getInitialLanguage = (): 'en' | 'es' => {
  if (!storageAvailable('localStorage')) return 'en';
  const existingPreference = window.localStorage.getItem('language');
  if (existingPreference !== 'en' && existingPreference !== 'es') return 'en';
  return existingPreference || 'en';
};

export const getCC = () => {
  if (storageAvailable('localStorage')) {
    return window.localStorage.getItem(`PI-CC`) || '';
  }
  return '';
};
