import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LanguageIcon from '@material-ui/icons/Language';
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import useStoreState from 'hooks/useStoreState';
import useStoreActions from 'hooks/useStoreActions';
import Link from 'components/LoginLink';
import { setLang } from 'store/model/language/language.types';
import { useTranslation } from 'react-i18next';

import hexToRGBA from 'utils/hexToRGBA';

const useClasses = makeStyles((theme: Theme) => ({
  footer: {
    flexGrow: 0,
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px',
    width: 'calc(100% - 32px)',
  },
  footer_row: {
    display: 'flex',
    flexflow: 'row wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footer_link: {
    fontSize: '13px',
    margin: '3px',
    color: theme.palette.common.white,
    textDecoration: 'none',
    fontFamily: 'Open Sans',
    textAlign: 'center',
  },
  h_sep: {
    height: '22px',
    marginRight: '6px',
    marginLeft: '6px',
    width: '1px',
    background: hexToRGBA(theme.palette.common.white, 0.6),
  },
  button_link: {
    textTransform: 'none',
  },
}));

const openExternalLink = (link: string) => {
  window.open(link, '_blank');
};

const year = new Date().getFullYear();

const Footer = () => {
  const dispatch = useStoreActions();
  const { t } = useTranslation('Footer');
  const theme = useTheme();
  const { footer, footer_link, button_link, h_sep, footer_row } = useClasses(theme);
  const history = useHistory();
  const isLoggedIn = useStoreState((s) => s.user.isLoggedIn === true);
  const allowAddisonPass = useStoreState(({ organization }) => Boolean(organization?.modules?.['addison-pass']));

  return (
    <div className={footer}>
      <div className={footer_row}>
        <Button color='secondary' size='small' startIcon={<LanguageIcon />} onClick={() => dispatch(setLang('en'))} className={button_link}>
          English
        </Button>
        <Button color='secondary' size='small' onClick={() => dispatch(setLang('es'))} className={button_link}>
          Español
        </Button>
      </div>
      <div className={footer_row}>
        {isLoggedIn && (
          <>
            <Link className={footer_link} name={t('reportBug')} onClick={() => history.push('/reportAproblem')} />
            <div className={h_sep} />
            <Link className={footer_link} name={t('submitFeedback')} onClick={() => history.push('/feedback')} />
            <div className={h_sep} />
          </>
        )}
        <Link className={footer_link} onClick={() => openExternalLink(process.env.REACT_APP_SP_TERMSOFSERVICE!)} name={t('TOS')} />
        <div className={h_sep} />

        {allowAddisonPass && isLoggedIn && <Link className={footer_link} onClick={() => history.push('/qr-reader')} name={t('readQR')} />}
      </div>
      <div className={footer_row}>
        <Typography className={footer_link} variant='body1'>
          Copyright © {year} AddisonCare. All Rights Reserved.
        </Typography>
      </div>
    </div>
  );
};

export default Footer;
