import { mapTo, tap } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { SET_LANG } from 'store/model/language/language.types';

/*
 *  Action In: SET_LANG
 *  Actions Out: UPDATE_LOCAL_STORAGE
 *  Doesn't actually map to any used action
 */
export const langTogEpic: Epic = (action$, store$) =>
  action$.pipe(
    ofType(SET_LANG),
    tap(() => {
      if (!window.localStorage) return;
      window.localStorage.setItem('language', store$.value.language);
    }),
    mapTo({
      type: 'UPDATE_LOCAL_STORAGE',
    }),
  );
