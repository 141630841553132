import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated as a } from 'react-spring';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import BarChartRoundedIcon from '@material-ui/icons/BarChartRounded';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CodeIcon from '@material-ui/icons/Code';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import SupervisorAccountRoundedIcon from '@material-ui/icons/SupervisorAccountRounded';
import PersonIcon from '@material-ui/icons/Person';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import isEqual from 'lodash/isEqual';
import { useCustomCompareEffect } from 'use-custom-compare';
import ShareIcon from '@material-ui/icons/Share';
import addisonIcon from 'assets/Icon_addison-white.svg';

import useAuth from 'hooks/useAuth';
import useNotify from 'hooks/useNotify';
import useStoreState from 'hooks/useStoreState';
import useMotionReduce from 'hooks/useMotionReduce';

import { signOut } from 'functions/Firebase/firebaseAuth';
import AddisonBadge from 'components/AddisonBadge/AddisonBadge';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    minWidth: '225px',
    justifyContent: 'flex-end',
  },
  paper: {
    display: 'flex',
    borderRadius: '.5em',
    backgroundColor: (theme: any) => theme.palette.blurBackgroundColor,
    backdropFilter: 'blur(40px)',
    justifyContent: 'center',
  },
  icon: {
    fontSize: '24px !important',
  },
});

const ProfileWidget = () => {
  const notify = useNotify();
  const history = useHistory();
  const { t } = useTranslation('ProfileDropdown');
  const { authDetails } = useAuth();
  const { displayName, email, admin, manager, bizDev, developer } = authDetails || {};
  const [open, setOpen] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const anchorRef = useRef<null | HTMLButtonElement>(null);
  const theme = useTheme();
  const classes = useStyles(theme);
  const prefersReducedMotion = useMotionReduce();
  const notifications = useStoreState(({ user }) => user.notifications);
  const allowAddisonPass = useStoreState(({ organization }) => Boolean(organization?.modules?.['addison-pass']));

  const profileSpring = useSpring({
    opacity: 1,
    from: {
      opacity: 0,
    },
    immediate: prefersReducedMotion,
  });

  const prevOpen = useRef(open);
  useEffect(() => {
    if (anchorRef.current && prevOpen.current && !open) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  useCustomCompareEffect(
    () => {
      if (notifications.length > 0) setUnreadNotifications(notifications.filter((e) => !e.read).length);
    },
    [notifications],
    (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps)
  );

  const closeDropdown = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event: any) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  interface Field {
    label: string;
    icon: any;
    onClick: (event: any) => undefined | void;
  }

  const conditional = (allow: boolean | undefined, field: Field): Array<Field> => (Boolean(allow) ? [field] : []);

  const fields: Field[] = [
    {
      label: t('Profile'),
      icon: <PersonIcon color='secondary' />,
      onClick: (event: any) => {
        closeDropdown(event);
        history.push('/profile');
      },
    },
    {
      label: t('My Readings'),
      icon: <BarChartRoundedIcon color='secondary' />,
      onClick: () => {
        history.push('/profile/my-readings');
      },
    },
    {
      label: t('My Surveys'),
      icon: <AssignmentIcon color='secondary' />,
      onClick: () => {
        history.push('/profile/my-surveys');
      },
    },
    {
      label: t('My Notifications'),
      icon: (
        <AddisonBadge badgeContent={unreadNotifications}>
          {unreadNotifications === 0 && <NotificationsIcon color='secondary' />}
          {unreadNotifications !== 0 && <NotificationsActiveIcon color='secondary' />}
        </AddisonBadge>
      ),
      onClick: () => {
        history.push('/profile/user-notifications');
      },
    },
    ...conditional(allowAddisonPass, {
      label: t(`Addison Pass`),
      icon: <img width='21' src={addisonIcon} alt={'Addison Icon'} />,
      onClick: () => {
        history.push('/results');
      },
    }),
    {
      label: t(`Export Todays Vitals`),
      icon: <ShareIcon color='secondary' />,
      onClick: () => {
        history.push('/results/todays-vitals');
      },
    },
    ...conditional(admin || manager || developer, {
      label: admin || developer ? t('Admin') : t('Management'),
      icon: <SupervisorAccountRoundedIcon color='secondary' />,
      onClick: (event: any) => {
        closeDropdown(event);
        history.push('/admin');
      },
    }),
    ...conditional(developer, {
      label: t('Dev Tools'),
      icon: <CodeIcon color='secondary' />,
      onClick: (event: any) => {
        closeDropdown(event);
        history.push('/dev-dashboard');
      },
    }),
    ...conditional(bizDev || developer, {
      label: t('BizDev Tools'),
      icon: <BusinessCenterIcon color='secondary' />,
      onClick: (event: any) => {
        closeDropdown(event);
        history.push('/business-dashboard');
      },
    }),
    {
      label: t('Logout'),
      icon: <ExitToAppRoundedIcon color='secondary' />,
      onClick: (event: any) => {
        closeDropdown(event);
        signOut();
        notify('success', t('Successfully logged out'));
        history.push('/');
      },
    },
  ];

  return (
    <a.div style={profileSpring} className={classes.root}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        endIcon={
          <AddisonBadge variant='dot' invisible={unreadNotifications === 0}>
            <AccountCircleIcon className={classes.icon} />
          </AddisonBadge>
        }
        onClick={() => setOpen(!open)}
      >
        {displayName || email || ''}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement='bottom'
        style={{
          zIndex: 1,
        }}
      >
        <Paper className={classes.paper}>
          <ClickAwayListener onClickAway={closeDropdown}>
            <MenuList autoFocusItem={open} id='menu-list-grow' onKeyDown={handleListKeyDown}>
              {fields.map((e: Field) => (
                <MenuItem onClick={e.onClick} key={e.label}>
                  <ListItemIcon>{e.icon}</ListItemIcon>
                  <Typography variant='inherit' color='secondary'>
                    {e.label}
                  </Typography>
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </a.div>
  );
};

export default ProfileWidget;
