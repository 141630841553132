import React, { useMemo, createElement, FunctionComponent as FC, FunctionComponentElement as FCE } from 'react';
import { Route } from 'react-router-dom';
import compose from 'lodash/fp/compose';
import map from 'lodash/fp/map';
import useStoreState from 'hooks/useStoreState';
import { GlobalStore, OrganizationModules } from 'types/store';

interface RouteList {
  [key: string]: FC<{}>;
}

const modules = ({ organization }: GlobalStore) => organization.modules;
const rString = () => Math.random().toString(36);
const keyedElement = ([path, component]: [string, FC]) => createElement(Route, { key: rString(), component, path, exact: true }, null);
const routify: (rs: RouteList) => FCE<{}>[] = compose(map(keyedElement), Object.entries);

/*
 *  Constructs a hook which returns protected routes
 *
 */

const makeRouteGuard = (g: (s: GlobalStore) => boolean, routes: (modules: OrganizationModules) => RouteList) => {
  return (): null | React.ReactNode[] => {
    const Routes = routify(routes(useStoreState(modules)));
    const allow = useStoreState(g);
    return useMemo(() => (allow ? Routes : null), [allow, Routes]);
  };
};

export default makeRouteGuard;
