import { catchError } from 'rxjs/operators';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  createEpicMiddleware,
  combineEpics,
  ActionsObservable,
  StateObservable,
} from 'redux-observable';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import * as notifEpics from 'store/model/notifications/notif.epics';
import * as langEpics from 'store/model/language/language.epics';
import * as userEpics from 'store/model/user/user.epics';
import reducer from 'store/reducer';
import isDevEnv from 'utils/isDevEnv';

const epics = [
  ...Object.values({
    ...notifEpics,
    ...langEpics,
    ...userEpics,
  }),
];

const rootEpic = (
  action$: ActionsObservable<any>,
  store$: StateObservable<any>,
  dependencies: any,
) =>
  combineEpics(...epics)(action$, store$, dependencies).pipe(
    catchError((err, source) => {
      console.error(err);
      return source;
    }),
  );

const epicMiddleware = createEpicMiddleware();

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const store = createStore(
  reducer,
  composeWithDevTools(
    applyMiddleware(epicMiddleware),
    ...((!isDevEnv() && [applyMiddleware(LogRocket.reduxMiddleware()), sentryReduxEnhancer]) || []),
  ),
);

epicMiddleware.run(rootEpic);

export default store;
