import { fbConnector } from '../../containers/Login/fireBaseConfig';

export const fireFunctions = fbConnector.functions();

export const getQrJWT = fireFunctions.httpsCallable('getQrJWT');

export const decodeQrJWT = fireFunctions.httpsCallable('decodeQrJWT');

export const validateXmit = fireFunctions.httpsCallable('validatexmit');

export const makeAdmin = fireFunctions.httpsCallable('makeAdmin');

export const emailReading = fireFunctions.httpsCallable('emailReading');

export const addFlag = fireFunctions.httpsCallable('addFlag');

export const removeUser = fireFunctions.httpsCallable('removeUser');

export const removeFlag = fireFunctions.httpsCallable('removeFlag');

export const onboardPaidOrganization = fireFunctions.httpsCallable('onboardPaidOrganization');

export const disableLocation = fireFunctions.httpsCallable('disableLocation');

export const getUser = fireFunctions.httpsCallable('getUser');

export const getAdmins = fireFunctions.httpsCallable('getAdmins');

export const getNotifications = fireFunctions.httpsCallable('getNotifications');

export const updateLocation = fireFunctions.httpsCallable('updateLocation');

export const onboardOrganization = fireFunctions.httpsCallable('onboardOrganization');

export const sendNotification = fireFunctions.httpsCallable('sendNotification');

export const getUserCustomClaims = fireFunctions.httpsCallable('getUserCustomClaims');

export const changeUserLocation = fireFunctions.httpsCallable('changeUserLocation');

export const createUser = fireFunctions.httpsCallable('createUser');

export const setUserCustomClaims = fireFunctions.httpsCallable('setUserCustomClaims');

export const verifyAccount = fireFunctions.httpsCallable('verifyAccount');

export const validateInvoiceCode = fireFunctions.httpsCallable('validateInvoiceCode');

export const addLocation = fireFunctions.httpsCallable('addLocation');

export const createDashboardSubscriber = fireFunctions.httpsCallable('createDashboardSubscriber');

export const bulkUserChangeLocation = fireFunctions.httpsCallable('bulkChangeUserLocation');

export const getStripeCustomer = fireFunctions.httpsCallable('getStripeCustomer');

export const updateStripeCustomer = fireFunctions.httpsCallable('updateStripeCustomer');

export const updateStripePaymentSource = fireFunctions.httpsCallable('updateStripePaymentSource');

export const createStripePaymentSource = fireFunctions.httpsCallable('createStripePaymentSource ');

export const updateStripeSubscription = fireFunctions.httpsCallable('updateStripeSubscription');

export const createStripeSubscription = fireFunctions.httpsCallable('createStripeSubscription');

export const topicSubscribe = fireFunctions.httpsCallable('topicSubscribe');

export const pushMessage = fireFunctions.httpsCallable('pushMessage');

export const disableOrganization = fireFunctions.httpsCallable('disableOrganization');

export const userIdPushMessage = fireFunctions.httpsCallable('userIdPushMessage');

export const upsShipRate = fireFunctions.httpsCallable('upsShipRate');

export const createInvoiceKey = fireFunctions.httpsCallable('createInvoiceKey');

export const disableSingleUser = fireFunctions.httpsCallable('disableSingleUser');

export const disableMultipleUsers = fireFunctions.httpsCallable('disableMultipleUsers');

export const getTodaysVitals = fireFunctions.httpsCallable('getTodaysVitals');

export const organizationStatus = fireFunctions.httpsCallable('organizationStatus');

export const getOrganizationMetrics = fireFunctions.httpsCallable('getOrganizationMetrics');

export const individualMetrics = fireFunctions.httpsCallable('individualMetrics');

export const bulkChangeSubsMultipleLocation = fireFunctions.httpsCallable('bulkChangeSubsMultipleLocation');

export const removeOrganizationModule = fireFunctions.httpsCallable('removeOrganizationModule');
