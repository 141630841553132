import { BloodPressureReading } from 'types/store';
import FirestoreTimestamp from 'utils/DecodeTimestamp';
import * as D from 'io-ts/lib/Decoder';

const DecodeBloodPressure: D.Decoder<unknown, BloodPressureReading> = D.type({
  date: FirestoreTimestamp,
  warning: D.boolean,
  batchUid: D.string,
  userUid: D.string,
  type: D.literal('blood-pressure-monitor'),
  readings: D.type({
    systolic: D.number,
    diastolic: D.number,
    pulseRate: D.number,
  }),
});

export default DecodeBloodPressure;
