import React from 'react';
import { useTransition, animated as a } from 'react-spring';
import { makeStyles } from '@material-ui/core/styles';
import AddisonAlert from 'components/AddisonAlert/AddisonAlert';

import { useNotifyContext } from 'notificationsContext/Context';
import usePushNotifications from 'hooks/usePushNotifications';

const useClasses = makeStyles({
  pi_notifications: {
    position: 'fixed',
    width: '320px',
    bottom: '14px',
    right: '0vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    zIndex: 1301,
  },
  anim_notif: {
    margin: '14px 0px 14px 0px',
  },
});

const Notifications = () => {
  const {
    state: { notifications },
    dispatch,
  } = useNotifyContext();
  usePushNotifications();
  const animNotif = useTransition(notifications, (n) => n.uid, {
    from: { transform: `translate3d(350px,0,0)`, opacity: 1 },
    enter: { transform: `translate3d(0,0,0)`, opacity: 1 },
    leave: { transform: `translate3d(0,0,0)`, opacity: 0 },
  });
  const { pi_notifications, anim_notif } = useClasses(notifications.length);
  return (
    <div className={pi_notifications}>
      {animNotif.map(({ item: { severity, body, uid, timeout }, key, props: animStyles }) => (
        <a.div key={key} style={animStyles} className={anim_notif}>
          <AddisonAlert
            severity={severity}
            body={body}
            timeout={timeout}
            onClose={() => dispatch({ type: 'REMOVE_NOTIFICATION', value: uid })}
          />
        </a.div>
      ))}
    </div>
  );
};

export default Notifications;
