import { Dialog } from 'types/language';
import { Selectable } from 'utils/select';

/*
 *  Errors can be found here:
 *  https://firebase.google.com/docs/reference/js/firebase.auth.Error
 *
 */

const firebaseErrors: Selectable<Dialog<string>> = {
  'auth/user-token-expired': {
    en: 'Log in has expired, please sign in again.',
    es: 'Su sesión ha expirado, por favor inicie sesión nuevamente.',
  },
  'auth/invalid-user-token': {
    en: 'Log in has expired, please sign in again.',
    es: 'Su sesión ha expirado, por favor inicie sesión nuevamente.',
  },
  'auth/wrong-password': {
    en: 'Email or Password Not Recognized',
    es:
      'Correo electrónico o contraseña no válidos/Correo electrónico y contraseña son requeridos.',
  },
  'auth/user-not-found': {
    en: 'Email or Password Not Recognized',
    es:
      'Correo electrónico o contraseña no válidos/Correo electrónico y contraseña son requeridos.',
  },
  'auth/network-request-failed': {
    en: 'There was a problem reaching the network, check your connection and try again.',
    es:
      'Hubo un problema conectadose a Internet, revise su connección a Internet e intente nuevamente.',
  },
  'auth/too-many-requests': {
    en:
      'This device has exceeded the maximum allowed attempts to log in, try again in a few minutes.',
    es: 'El número de intentos para iniciar sesión ha side excedido, intente nuevamente más tarde.',
  },
  'auth/unauthorized-domain': {
    en: `Oops!  It seems like you're trying to sign in with the wrong URL, make sure you're signing in at https://protectorinitiative.health!`,
    es:
      'Uy! Parece que estáintentnado iniciar sesión con una dirección incorrecta, asegurese que está iniciando sesión en https://protectorinitiative.health ',
  },
  'auth/user-disabled': {
    en:
      'Oops!  It looks like your account has been disabled.  Please contact Addison Customer Support.',
    es: 'Uy! Su cuenta a sido desactivada. Por favor contacte Addison Atención al Cliente',
  },
  'auth/weak-password': {
    en: 'Password must be at least six characters',
    es: 'Contraseña tiene que ser al menos seis caracteres',
  },
  'auth/email-already-in-use': {
    en: 'An account with that email already exists',
    es: 'Una cuenta con éste correo electrónico ya existe',
  },
  'auth/invalid-email': {
    en: `Oops!  It looks like that email isn't formatted properly.`,
    es: 'Uy! El formato del correo electrónico es incorrecto.',
  },
  'auth/account-exists-with-different-credential': {
    en:
      'That email already exists with a different log in method.  Please contact Addison Customer Support to resolve this issue.',
    es:
      'Este correo electrónico ya existe con un método diferente de inicio de sesión. Porfavor contacte Addison Atencón al Cliente para resolver este problema.',
  },
  'auth/web-storage-unsupported': {
    en: `Oops!  It looks like you're using an outdated browser, or have disabled certain browser features.  Either upgrade your choice of browser, or contact Addison Customer Support.`,
    es:
      'Uy! Parece ser que su navegador no está actualizado o tiene deshabilitadas algunas características. Actualice su navegador o contacte  Addison Atención al Cliente.',
  },
  default: {
    en: `Oops!  We've run into a problem!  Please contact Addison Customer Support if the issue persists.`,
    es:
      'Uy! Un problema ha ocurrido! Por favor contacte Addsion Atención al Cliente si el problema continúa',
  },
};

export default firebaseErrors;
