import action from 'utils/action';
import { SET_THEME, SetTheme } from 'store/model/theme/theme.types';

export type ThemeActionTypes = SetTheme;

const themeReducer = action((store, action) => {
  switch (action.type) {
    case SET_THEME:
      (({ payload }) => {
        store.theme = payload;
      })(action);
      break;
  }
});

export default themeReducer;
