/* eslint-disable no-console */
import firebase from 'firebase/app';
import { createUser } from 'functions/Firebase/firebaseFunctions';
import { fireAuth } from '../../containers/Login/fireBaseConfig';

export const emailSignIn = async (email: string, password: string) => {
  const response = await fireAuth.signInWithEmailAndPassword(email, password).then(async () => {
    if (!fireAuth.currentUser) {
      return { error: true };
    }
    const token = await fireAuth.currentUser.getIdToken(true);
    if (token) {
      return { error: false };
    }
    return { error: true };
  });
  return response;
};

export const emailSignUp = async (email: string, password: string, xmit: string, firstName: string, lastName: string, phoneNumber: string) => {
  const userData = {
    email,
    xmit,
    firstName,
    lastName,
    organization: 'root',
    location: 'main',
    unattached: true,
    password,
    phoneNumber,
  };
  const response: any = await createUser(userData).catch((e) => ({
    error: true,
    e,
  }));
  return { error: response.error || false, user: response.user };
};

export const resetUserPassword = async (email: string) => {
  let response = { error: false, msg: {} };
  await fireAuth.sendPasswordResetEmail(email).catch((err) => {
    response.error = true;
    response.msg = err;
  });
  return response;
};

export const signOut = async () => {
  await fireAuth.signOut().then(() => {
    return { error: false, message: 'signed out successfully' };
  });
};

// log in with phone number
export const phoneVerify = async (phoneNumber: string, appVerifier: any) => {
  const provider = await new firebase.auth.PhoneAuthProvider();
  return provider
    .verifyPhoneNumber(phoneNumber, appVerifier)
    .then((verificationId) => {
      return verificationId;
    })
    .catch((error) => {
      return { error: true, ...error };
    });
};

export const phoneLink = async (verification: string, code: string) => {
  const credential = firebase.auth.PhoneAuthProvider.credential(verification, code);
  if (!fireAuth.currentUser) {
    return { error: true };
  }
  await fireAuth.currentUser.linkWithCredential(credential);
};

export const uploadFile = (doc: { org: string; loc: string | null; file: any }) => {
  const storageRef = firebase.app().storage(process.env.REACT_APP_CSV_BUCKET).ref();
  if (doc.org && (doc.loc === undefined || doc.loc === '' || doc.loc === 'All Locations' || doc.loc === null)) {
    storageRef.child(`${doc.org}/${doc.file.name}`).put(doc.file);
  } else {
    storageRef.child(`${doc.org}/${doc.loc}/${doc.file.name}`).put(doc.file);
  }
};
