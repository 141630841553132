import { lazy } from 'react';
import { OrganizationModules } from 'types/store';

const UpdateProfile = lazy(
  () => import('containers/UserProfile/UpdateProfile')
);
const CreateNewPassword = lazy(
  () => import('containers/UserProfile/CreateNewPassword')
);

const newUserRoutes = (modules: OrganizationModules) => ({
  '/profile/updatepassword': CreateNewPassword,
  '/profile/updateprofile': UpdateProfile,
  '/': CreateNewPassword,
});

export default newUserRoutes;
