import cuid from 'cuid';
import { getInitialLanguage, getCC } from 'store/localStorage';
import { GlobalStore } from 'types/store';

const sessionBatchId = cuid();

export const initOrganizationModules = {
  'addison-pass': false,
  'blood-pressure-monitor': false,
  'pulse-ox': false,
  surveys: false,
  thermometer: false,
  'weight-scale': false,
  glucose: false,
  spirometer: false,
};

const init: GlobalStore = {
  initialized: false,
  pendingSubscriptions: {},
  user: {
    isLoggedIn: undefined,
    isAdmin: false,
    isKiosk: false,
    isBizDev: false,
    isManager: false,
    isDeveloper: false,
    authDetails: null,
    lastCC: getCC(),
    details: {
      uid: null,
      firstName: null,
      lastName: null,
      xmit: null,
      organization: null,
      location: null,
      phoneNumber: null,
      device: null,
      devices: [],
      allowPushEmail: null,
      allowPushText: null,
      allowPushNotification: null,
    },
    readings: [],
    surveys: [],
    notifications: [],
  },
  organization: {
    incubationPeriod: null,
    vitalsSpec: {
      temperature_f: {
        device: 'thermometer',
        type: 'temperature',
        unit: '°F',
        max: 99,
        min: 95,
        precision: 1,
        addisonPassWarning: true,
      },
      temperature_c: {
        device: 'thermometer',
        type: 'temperature',
        unit: '°C',
        max: 37.22,
        min: 35,
        precision: 1,
        addisonPassWarning: true,
      },
      o2sat_percentage: {
        device: 'pulse-ox',
        type: 'o2Sat',
        unit: '%SpO2',
        max: 100,
        min: 92,
        precision: 0,
        addisonPassWarning: true,
      },
      heartrate_bpm: {
        device: 'blood-pressure-monitor',
        type: 'heartrate',
        unit: 'bpm',
        max: 100,
        min: 60,
        precision: 0,
        addisonPassWarning: false,
      },
      perfindex_percentage: {
        device: 'pulse-ox',
        type: 'perfindex',
        unit: '%PI',
        max: 20,
        min: 0.02,
        precision: 0,
        addisonPassWarning: false,
      },
      systolic_mmHg: {
        device: 'blood-pressure-monitor',
        type: 'systolic',
        unit: 'mmHg',
        max: 120,
        min: 90,
        precision: 0,
        addisonPassWarning: true,
      },
      diastolic_mmHg: {
        device: 'blood-pressure-monitor',
        type: 'diastolic',
        unit: 'mmHg',
        max: 80,
        min: 60,
        precision: 0,
        addisonPassWarning: false,
      },
      weight_lbs: {
        device: 'weight-scale',
        type: 'weight',
        unit: 'lbs',
        max: 300,
        min: 100,
        precision: 0,
        addisonPassWarning: false,
      },
    },
    requiredDevices: {
      thermometer: true,
      'pulse-ox': true,
      'weight-scale': false,
      'blood-pressure-monitor': false,
      'covid-19-survey': false,
    },
    modules: initOrganizationModules,
  },
  notifications: [],
  language: getInitialLanguage(),
  errors: [],
  latestReadings: {
    honestyAgreement: false,
    batchUid: sessionBatchId,
    wellnessQr: {
      uri: null,
      jwt: null,
      hasDays: null,
      requiredDays: null,
      hasWarnings: null,
    },
    survey: [],
    thermometer: {
      batchUid: sessionBatchId,
      type: 'thermometer',
      inExcess: null,
      reading: {
        f: null,
        c: null,
      },
    },
    pulseOximeter: {
      batchUid: sessionBatchId,
      type: 'pulse-ox',
      inExcess: null,
      reading: {
        pulseRate: null,
        o2Sat: null,
        perfIndex: null,
      },
    },
    bloodPressure: {
      batchUid: sessionBatchId,
      type: 'blood-pressure-monitor',
      inExcess: null,
      reading: {
        systolic: null,
        diastolic: null,
        pulseRate: null,
      },
    },
  },
  theme: {},
};

export default init;
