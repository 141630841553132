import React from 'react';
import { useSpring, animated as a } from 'react-spring';
import { makeStyles } from '@material-ui/core/styles';
import Lottie from 'react-lottie';
import animationAddisonTablet from 'assets/LoginAnim_AddisonWTablet.json';

const useClasses = makeStyles({
  addison_loading: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

interface AddisonLoaderProps {
  width?: number;
  height?: number;
  immediate?: boolean;
}

const AddisonLoader = ({ width = 131, height = 131, immediate = false }: AddisonLoaderProps) => {
  const { addison_loading } = useClasses();
  const fadeIn = useSpring({
    opacity: 1,
    from: {
      opacity: 0,
    },
    immediate,
  });
  return (
    <a.div style={fadeIn} className={addison_loading} data-testid="loading-animation">
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationAddisonTablet,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid meet',
          },
        }}
        width={width}
        height={width}
      />
    </a.div>
  );
};

export default AddisonLoader;
