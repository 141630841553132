import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import store from 'store/redux';
import { StylesProvider } from '@material-ui/core/styles';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import { generateClassName } from 'setupStyles';
import './i18n';
import LoadingScreen from 'containers/LoadingScreen/LoadingScreen';
import isDevEnv from 'utils/isDevEnv';

if (!isDevEnv()) {
  LogRocket.init('guk0cx/protector-initiative');
  Sentry.init({
    dsn: 'https://068aa6fde7bb4d10a1af2a8123b9c66c@o364041.ingest.sentry.io/5513465',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.Suspense fallback={<LoadingScreen />}>
    <StylesProvider generateClassName={generateClassName}>
      <Provider store={store}>
        <App />
      </Provider>
    </StylesProvider>
  </React.Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
