import { lazy } from 'react';
import { OrganizationModules } from 'types/store';

const UpdateProfile = lazy(() => import('containers/UserProfile/UpdateProfile'));
const CreateNewPassword = lazy(() => import('containers/UserProfile/CreateNewPassword'));
const UserFeedback = lazy(() => import('containers/UserFeedback/UserFeedback'));
const BugReporter = lazy(() => import('containers/BugReporter/BugReporter'));
const SelectDevice = lazy(() => import('containers/SelectDevice'));
const UserProfile = lazy(() => import('containers/UserProfile'));
const BtPulseOx = lazy(() => import('containers/PulseOx/PulseOx.Bluetooth'));
const ManPulseOx = lazy(() => import('containers/PulseOx/PulseOx.Manual'));
const SelPulseOx = lazy(() => import('containers/PulseOx/PulseOx.SelectMethod'));
const BtTherm = lazy(() => import('containers/Thermometer/Thermometer.Bluetooth'));
const ManTherm = lazy(() => import('containers/Thermometer/Thermometer.Manual'));
const SelTherm = lazy(() => import('containers/Thermometer/Thermometer.SelectMethod'));
const BtBp = lazy(() => import('containers/BloodPressure/BloodPressure.Bluetooth'));
const ManBp = lazy(() => import('containers/BloodPressure/BloodPressure.Manual'));
const SelBp = lazy(() => import('containers/BloodPressure/BloodPressure.SelectMethod'));
const CovidSurvey = lazy(() => import('containers/CovidSurvey/CovidSurvey'));
const SendEmail = lazy(() => import('containers/SendEmail/SendEmail'));
const ShareResults = lazy(() => import('containers/ShareResults/ShareResults'));
const QRReader = lazy(() => import('containers/QRReader'));
const SubscriberReadings = lazy(() => import('containers/SubscriberReadings/SubscriberReadings'));
const SubscriberSurveys = lazy(() => import('containers/SubscriberSurveys/SubscriberSurveys'));
const UserNotifications = lazy(() => import('containers/DisplayUserNotifications/DisplayUserNotifications'));
const ShareTodaysVitals = lazy(() => import('containers/TodaysVitals/ShareTodaysVitals'));

const allowRoute = (modules: OrganizationModules, key: keyof OrganizationModules, route: Record<string, any>): Record<string, any> =>
  modules && modules[key] ? route : {};

const subscriberRoutes = (m: OrganizationModules) => ({
  '/profile/updatepassword': CreateNewPassword,
  '/profile/updateprofile': UpdateProfile,
  '/profile': UserProfile,
  '/profile/my-readings': SubscriberReadings,
  '/profile/user-notifications': UserNotifications,
  '/results/todays-vitals': ShareTodaysVitals,
  '/results/send-email': SendEmail,
  '/feedback': UserFeedback,
  '/reportAproblem': BugReporter,

  ...allowRoute(m, 'surveys', {
    '/profile/my-surveys': SubscriberSurveys,
  }),

  ...allowRoute(m, 'pulse-ox', {
    '/pulse-ox/manual-entry': ManPulseOx,
    '/pulse-ox/bluetooth': BtPulseOx,
    '/pulse-ox': SelPulseOx,
  }),

  ...allowRoute(m, 'thermometer', {
    '/thermometer/manual-entry': ManTherm,
    '/thermometer/bluetooth': BtTherm,
    '/thermometer': SelTherm,
  }),

  ...allowRoute(m, 'blood-pressure-monitor', {
    '/blood-pressure/manual-entry': ManBp,
    '/blood-pressure/bluetooth': BtBp,
    '/blood-pressure': SelBp,
  }),

  ...allowRoute(m, 'surveys', {
    '/survey/covid-19': CovidSurvey,
  }),

  ...allowRoute(m, 'addison-pass', {
    '/qr-reader': QRReader,
    '/results': ShareResults,
  }),

  '/': SelectDevice,
});

export default subscriberRoutes;
