import React from 'react';
import { animated as a, useTrail } from 'react-spring';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _Typography from '@material-ui/core/Typography';
import _Button from '@material-ui/core/Button';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import { makeStyles } from '@material-ui/core/styles';
import { pipe } from 'fp-ts/lib/function';
import * as R from 'fp-ts/lib/Record';
import * as O from 'fp-ts/lib/Option';
import LoadingScreen from 'containers/LoadingScreen/LoadingScreen';
import useMotionReduce from 'hooks/useMotionReduce';
import useStoreState from 'hooks/useStoreState';
import subscriberRoutes from 'containers/Routes/subscriberRoutes';

const Typography = a(_Typography);
const Button = a(_Button);

const useClasses = makeStyles({
  c: {
    display: 'flex',
    flexFlow: 'column',
    maxWidth: '850px',
  },
  fourOhFour: {
    fontWeight: 'bold',
  },
  title: {
    textAlign: 'center',
  },
  b: {
    fontWeight: 900,
  },
  gh: {
    textTransform: 'none',
    fontSize: '24px',
  },
});

const subRoutes = subscriberRoutes({} as any);

const isGuardedRoute = (path: string): boolean =>
  pipe(
    subRoutes,
    R.lookup(path),
    O.fold(
      () => false,
      () => true
    )
  );

const NotFound = () => {
  const initialized = useStoreState(({ initialized }) => initialized);
  const isLoggedIn = useStoreState(({ user }) => user.isLoggedIn);
  const { pathname } = useLocation();
  const prefersReducedMotion = useMotionReduce();
  const [cS, fourOhFourS, titleS, linkS] = useTrail(4, {
    opacity: !isLoggedIn || initialized ? 1 : 0,
    from: {
      opacity: 0,
    },
    immediate: prefersReducedMotion,
    delay: 400,
  });
  const { c, b, fourOhFour, title, gh } = useClasses();
  const history = useHistory();
  const { t } = useTranslation('NotFound');

  if (!isLoggedIn || initialized)
    return (
      <a.div className={c} style={cS}>
        <Typography style={fourOhFourS} paragraph variant='h1' className={fourOhFour}>
          404
        </Typography>
        <Typography style={titleS} paragraph variant='h3' className={title}>
          Oops! <span className={b}>{pathname}</span> {isGuardedRoute(pathname) ? t('is only available while logged in') : t('was not found')}!
        </Typography>
        <Button onClick={() => history.push('/')} style={linkS} startIcon={<ArrowBackRoundedIcon />} className={gh}>
          {t('Return Home')}
        </Button>
      </a.div>
    );
  return <LoadingScreen />;
};

export default NotFound;
