import { useEffect } from 'react';
import firebase from 'firebase/app';

import useStoreActions from 'hooks/useStoreActions';
import useStoreState from 'hooks/useStoreState';
import useNotifyError from 'hooks/useNotifyError';

import { fireStore as firestore } from 'containers/Login/fireBaseConfig';
import { updateUserOrganization } from 'store/model/user/user.types';
import { initOrganizationModules as initMods } from '../init';
import { setHydrated, setUnhydrated } from 'store/model/user/user.types';

const dbRef = (organizationId: string) => firestore.collection('hcos').doc(organizationId);
const getModules = (snapshot: firebase.firestore.DocumentSnapshot) => (snapshot.exists ? (snapshot.data() || {}).modules || initMods : initMods);

const useNotificationSync = () => {
  const orgId = useStoreState(({ user }) => user?.details?.organization);
  const dispatch = useStoreActions();
  const notifyError = useNotifyError();

  useEffect(() => {
    let mounted = true;

    if (!orgId) return;

    const fetch = async () => {
      dispatch(setHydrated('modules'));
      const modules = getModules(await dbRef(orgId).get());
      if (!mounted) return;
      dispatch(updateUserOrganization({ modules }));
      dispatch(setUnhydrated('modules'));
    };

    fetch();
    return () => {
      mounted = false;
    };
  }, [orgId, dispatch, notifyError]);
};

export default useNotificationSync;
