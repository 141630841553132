import * as D from 'io-ts/lib/Decoder';
import DecodeTimestamp from 'utils/DecodeTimestamp';
import { SurveyData } from 'types/store';

const DecodeSurvey: D.Decoder<unknown, SurveyData> = D.type({
  batchUid: D.string,
  userUid: D.string,
  date: DecodeTimestamp,
  type: D.string,
  warning: D.boolean,
  answers: D.record(D.boolean),
});

export default DecodeSurvey;
