import { useEffect, useRef } from 'react';

import useStoreState from './useStoreState';
import useStoreActions from './useStoreActions';
import { setInitialized } from 'store/model/user/user.types';

const useStoreSync = () => {
  const pendingSubscriptions = useStoreState(({ pendingSubscriptions }) => pendingSubscriptions);
  const initialized = useStoreState(({ initialized }) => Boolean(initialized));
  const dispatch = useStoreActions();
  const timer: any = useRef(0);

  useEffect(() => {
    const done = Object.keys(pendingSubscriptions).every((e) => !Boolean(pendingSubscriptions[e]));

    if (!done || initialized || Object.keys(pendingSubscriptions).length === 0) return;

    // Set initialized to true
    dispatch(setInitialized(true));

    // eslint-disable-next-line
  }, [pendingSubscriptions, initialized]);

  useEffect(() => {
    if (initialized && timer.current) {
      clearTimeout(timer.current);
      return;
    }

    if (!timer.current && !initialized)
      timer.current = setTimeout(() => {
        dispatch(setInitialized(true));
      }, 4000);

    return () => {
      try {
        clearTimeout(timer.current);
      } catch (error) {}
    };

    // eslint-disable-next-line
  }, [initialized]);
};

export default useStoreSync;
