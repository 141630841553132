import { lazy } from 'react';
import { OrganizationModules } from 'types/store';

const Login = lazy(() => import('containers/Login/Login'));
const PasswordReset = lazy(() => import('containers/Registration/PasswordReset'));
const OrderForm = lazy(() => import('containers/OrderForm/index'));

const loginRoutes = (modules: OrganizationModules) => ({
  '/reset-password': PasswordReset,
  '/order-form': OrderForm,
  '/': Login,
});

export default loginRoutes;
