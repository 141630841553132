/* eslint-disable no-console */
import firebase from 'firebase/app';
import 'firebase/auth'; // for authentication
import 'firebase/storage'; // for storage
import 'firebase/database'; // for realtime database
import 'firebase/firestore'; // for cloud firestore
import 'firebase/functions'; // for cloud functions
import 'firebase/messaging'; // for messaging
import 'firebase/performance'; // for performance tracking

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const fbConnector = firebase.initializeApp(firebaseConfig);

export const fbPerformance = fbConnector.performance();

export const fireAuth = fbConnector.auth();

export const currentUser = () => {
  return fireAuth.currentUser;
};
export const fireStore = fbConnector.firestore();

export const fireStorageBulkUserCreation = () =>
  fbConnector.storage(process.env.REACT_APP_CSV_BUCKET);
