import { TemperatureReading } from 'types/store';
import FirestoreTimestamp from 'utils/DecodeTimestamp';
import * as D from 'io-ts/lib/Decoder';

const DecodeTemperature: D.Decoder<unknown, TemperatureReading> = D.type({
  date: FirestoreTimestamp,
  warning: D.boolean,
  batchUid: D.string,
  userUid: D.string,
  type: D.literal('thermometer'),
  readings: D.type({
    f: D.number,
    c: D.number,
  }),
});

export default DecodeTemperature;
