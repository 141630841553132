import { Subject } from 'rxjs';
import { useEffect } from 'react';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic';
import { Plugins } from '@capacitor/core';

import useStoreState from 'hooks/useStoreState';
import useNotify from 'hooks/useNotify';

import firebase from 'firebase/app';
import isMobile from 'utils/isMobile';

const mobile = isMobile();
const { PushNotifications } = Plugins;

// Browser capable of notifications
const notifyAvailable = (): boolean => {
  if (mobile) return true;
  return 'Notification' in window;
};

// Notifications have been allowed
const permissionAllowed = async (): Promise<boolean> => {
  if (mobile)
    return FCM.hasPermission()
      .then(() => true)
      .catch(() => false);

  return notifyAvailable() && Notification.permission === 'granted' && firebase.messaging.isSupported();
};

const initialSetUp = async () => {
  // Getting token is necessary so firebase doesn't bug out for some reason.
  if ((await permissionAllowed()) && !mobile) {
    firebase
      ?.messaging()
      ?.getToken({
        vapidKey: process.env.REACT_APP_MESSAGING_KEY,
      })
      .catch(() => {});
  }
};

const usePushNotification = () => {
  const notify = useNotify();
  const isLoggedIn = useStoreState(({ user }): boolean => Boolean(user.isLoggedIn));
  const { uid }: any = useStoreState(({ user }) => user.details);
  const allowPush = useStoreState(({ user }) => user.details.allowPushNotification);
  const notifyDevices = useStoreState(({ user }) => user.details.devices);

  useEffect(() => {
    initialSetUp();
    const allowPushNotification = allowPush && Boolean(localStorage.getItem(`pi_device_id-${uid}`));

    // Check if user is logged in
    if (!isLoggedIn || !allowPushNotification) return;

    const messageListener = async (payload: any) => {
      // Make sure notification is intended for this user and not
      // a different user with the same device token
      if (!mobile && !payload?.data?.userIds?.split(',')?.includes(uid)) return;
      if (payload?.data?.message) notify('info', payload.data.message, Infinity);
    };

    const onMessage$ = new Subject<firebase.User | null>();
    const setUpNotifications = async () => {
      if (!mobile) {
        const messaging = firebase.messaging();
        messaging.onMessage(onMessage$);
        onMessage$.subscribe(messageListener);
      } else {
        PushNotifications.addListener('pushNotificationReceived', messageListener);
      }
    };

    const setNotifications = async () => {
      // Make sure notifications are allowed and permission is granted.
      const notificationsAllowed = await permissionAllowed();
      if (notificationsAllowed) {
        try {
          setUpNotifications();
        } catch (err) {
          // Weird firebase bug causes error on first call but not second.
          // https://github.com/firebase/firebase-js-sdk/issues/2364#issuecomment-570820017
          if (err.code === 'messaging/token-unsubscribe-failed') setUpNotifications();
        }

        // If user manually disabled notifications after initially granting permission
      } else if (notifyAvailable()) {
        // Check if permissions where previously granted
        const pi_device_id = localStorage.getItem(`pi_device_id-${uid}`);
        if (!pi_device_id || pi_device_id === '' || !notifyDevices || !Array.isArray(notifyDevices)) return;

        // remove current entry in devices array
        const updatedArray = notifyDevices && Array.isArray(notifyDevices) ? notifyDevices.filter((e) => e?.piBrowserId !== pi_device_id) : [];

        const hasOtherDevice = updatedArray.some((e) => e?.active);
        const newMainDevice = hasOtherDevice ? updatedArray.filter((e) => e?.active)[0].device : null;

        if (updatedArray.length !== notifyDevices.length)
          firebase
            .firestore()
            .collection('users')
            .doc(uid)
            .update({
              device: newMainDevice,
              devices: updatedArray,
              allowPushNotification: hasOtherDevice,
            })
            .catch();
      }
    };

    setNotifications();
    return () => {
      if (mobile) PushNotifications.removeAllListeners();
      else onMessage$?.unsubscribe();
    };

    // eslint-disable-next-line
  }, [notify, isLoggedIn, allowPush]);
  return notify;
};
export default usePushNotification;

/**
 * 
  Make sure notifications work under these conditions

  1. Normal user flow - Enable notifications through admin page when browser notifications are set to (ask)
  2. Normal user flow - Enable notifications through admin page when browser notifications are set to (allow)
  3. Normal user flow - Enable notifications through admin page when browser notifications are set to (allow)
      after another user also enabled notifications on the same device/browser. 
  4. All api calls work under the above conditions
  5. All api calls work after notifications are disabled
  6. All api calls work after user disabled notifications manually through site setting 
  7. All api calls work when notifications are disabled on current account but enabled by another on the same device/browser
  8. Ensure all above tests also work on android

 * 
 */
