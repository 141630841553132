import React from 'react';
import { useSpring, animated as a } from 'react-spring';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ProfileDropdown from 'components/ProfileDropdown/ProfileDropdown';
import useStoreState from 'hooks/useStoreState';
import Logo from '../../graphics/Logo_Addison.svg';

const useClasses = makeStyles({
  nav_bar: {
    display: 'flex',
    width: 'calc(100% - 24px)',
    height: '50px',
    padding: '12px 12px 0px 12px',
    marginBottom: '24px',
    '@media (max-width: 499px)': {
      flexFlow: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '@media (min-width: 500px)': {
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  logo: {
    width: '225px',
    cursor: 'pointer',
    WebkitUserSelect: 'none',
    KhtmlUserSelect: 'none',
    MozUserSelect: 'none',
    OUserSelect: 'none',
    userSelect: 'none',
  },
  pi_display: {
    display: 'flex',
    width: 'calc(100% - 24px)',
    padding: '0px 12px 0px 12px',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
});

const NavBar = ({ children }: { children: React.ReactChildren }) => {
  const { nav_bar, logo, pi_display } = useClasses();
  const history = useHistory();
  const isLoggedIn = useStoreState(({ user }) => user.isLoggedIn === true);
  const logoSpring = useSpring({
    opacity: 1,
    from: {
      opacity: 0,
    },
  });
  return (
    <>
      <div className={nav_bar}>
        <a.img
          style={logoSpring}
          className={logo}
          src={Logo}
          alt="Addison Care Logo"
          onClick={() => history.push('/')}
        />
        {isLoggedIn && <ProfileDropdown />}
      </div>
      <div className={pi_display}>{children}</div>
    </>
  );
};

NavBar.propTypes = {
  children: PropTypes.element.isRequired,
};

export default NavBar;
