import { Subject, from, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { useEffect } from 'react';
import firebase from 'firebase/app';
import merge from 'lodash/fp/merge';
import useStoreActions from 'hooks/useStoreActions';
import useNotifyError from 'hooks/useNotifyError';
import { UserClaims } from 'types/store';
import { fireAuth } from 'containers/Login/fireBaseConfig';
import { userSignIn, userSignOut, setHydrated, setUnhydrated } from 'store/model/user/user.types';

const useAuthSync = () => {
  const dispatch = useStoreActions();
  const notifyError = useNotifyError();
  useEffect(() => {
    const authChange$ = new Subject<firebase.User | null>();
    const fbUnsub = fireAuth.onAuthStateChanged(authChange$);
    setHydrated('auth');
    authChange$
      .pipe(
        switchMap((user: firebase.User | null) =>
          user
            ? from(user.getIdTokenResult(true)).pipe(map(({ claims }) => userSignIn(merge(user, claims as UserClaims))))
            : of(userSignOut(), setUnhydrated('auth'))
        )
      )
      .subscribe(dispatch, (e) => {
        notifyError(e);
        dispatch(setUnhydrated('auth'));
      });
    return () => {
      authChange$.unsubscribe();
      fbUnsub();
    };
  }, [dispatch, notifyError]);
};

export default useAuthSync;
