import action from 'utils/action';
import checkThreshold from 'utils/checkThreshold';
import {
  SET_THERM,
  SET_PULSEOX,
  SET_SURVEY,
  SET_WELLNESS_URI,
  SET_BP,
  SET_AGREE,
  SetAgree,
  SetTherm,
  SetPulseOx,
  SetBp,
  SetSurvey,
  SetWellnessUri,
} from 'store/model/readings/readings.types';

export type ReadingActionTypes =
  | SetAgree
  | SetTherm
  | SetPulseOx
  | SetBp
  | SetSurvey
  | SetWellnessUri;

const readingsReducer = action((store, action) => {
  switch (action.type) {
    case SET_THERM:
      (({ payload: { f, c } }) => {
        const checkTemperatureThreshold = checkThreshold([
          store.organization.vitalsSpec.temperature_f,
          store.organization.vitalsSpec.temperature_c,
        ]);
        store.latestReadings.thermometer = {
          type: 'thermometer',
          inExcess: !checkTemperatureThreshold([f, c]),
          batchUid: store.latestReadings.batchUid,
          reading: { f, c },
        };
      })(action);
      break;
    case SET_PULSEOX:
      (({ payload: { pulseRate, o2Sat, perfIndex } }) => {
        const checkPulseOxThreshold = checkThreshold([
          store.organization.vitalsSpec.heartrate_bpm,
          store.organization.vitalsSpec.o2sat_percentage,
          store.organization.vitalsSpec.perfindex_percentage,
        ]);
        store.latestReadings.pulseOximeter = {
          batchUid: store.latestReadings.batchUid,
          inExcess: !checkPulseOxThreshold([pulseRate, o2Sat, perfIndex]),
          type: 'pulse-ox',
          reading: {
            pulseRate,
            o2Sat,
            perfIndex,
          },
        };
      })(action);
      break;
    case SET_BP:
      (({ payload: { pulseRate, systolic, diastolic } }) => {
        const checkBpThreshold = checkThreshold([
          store.organization.vitalsSpec.heartrate_bpm,
          store.organization.vitalsSpec.systolic_mmHg,
          store.organization.vitalsSpec.diastolic_mmHg,
        ]);
        store.latestReadings.bloodPressure = {
          batchUid: store.latestReadings.batchUid,
          inExcess: !checkBpThreshold([pulseRate, systolic, diastolic]),
          type: 'blood-pressure-monitor',
          reading: {
            pulseRate,
            systolic,
            diastolic,
          },
        };
      })(action);
      break;
    case SET_SURVEY:
      (({ payload }) => {
        store.latestReadings.survey.push(payload);
      })(action);
      break;
    case SET_WELLNESS_URI:
      (({ payload }) => {
        store.latestReadings.wellnessQr = payload;
      })(action);
      break;
    case SET_AGREE:
      (({ payload }) => {
        store.latestReadings.honestyAgreement = payload;
      })(action);
      break;
  }
});

export default readingsReducer;
