import { PulseOxReading } from 'types/store';
import FirestoreTimestamp from 'utils/DecodeTimestamp';
import * as D from 'io-ts/lib/Decoder';

const DecodePulseOx: D.Decoder<unknown, PulseOxReading> = D.type({
  date: FirestoreTimestamp,
  warning: D.boolean,
  batchUid: D.string,
  userUid: D.string,
  type: D.literal('pulse-ox'),
  readings: D.type({
    perfusionIndex: D.number,
    pulseRate: D.number,
    o2Sat: D.number,
  }),
});

export default DecodePulseOx;
