import merge from 'lodash/fp/merge';
import defaults from 'lodash/fp/defaultsDeep';
import omit from 'lodash/fp/omit';
import action from 'utils/action';
import {
  SET_CC,
  SetCC,
  SIGN_IN,
  UserSignIn,
  SIGN_OUT,
  UserSignOut,
  UPDATE_USER_DETAILS,
  UpdateUserDetails,
  UPDATE_USER_ORGANIZATION,
  UpdateUserOrganization,
  UPDATE_USER_READINGS,
  UpdateUserReadings,
  UPDATE_USER_SURVEYS,
  UpdateUserSurveys,
  UPDATE_USER_NOTIFICATIONS,
  UpdateUserNotifications,
  SetHydrated,
  SET_HYDRATED,
  SetUnhydrated,
  SET_UNHYDRATED,
  SetInitialized,
  SET_INITIALIZED,
} from 'store/model/user/user.types';
import init from 'store/init';

export type UserActionTypes =
  | UserSignIn
  | UserSignOut
  | UpdateUserDetails
  | UpdateUserOrganization
  | UpdateUserReadings
  | UpdateUserSurveys
  | UpdateUserNotifications
  | SetHydrated
  | SetUnhydrated
  | SetCC
  | SetInitialized;

const userReducer = action((store, action) => {
  switch (action.type) {
    case SIGN_IN:
      (({ payload: authDetails }) => {
        store.initialized = false;
        store.user.details.uid = authDetails.uid;
        store.user.authDetails = authDetails;
        store.user.isLoggedIn = true;
        store.user.isAdmin = Boolean(authDetails.admin);
        store.user.isKiosk = Boolean(authDetails.kiosk);
        store.user.isManager = Boolean(authDetails.manager);
        store.user.isDeveloper = Boolean(authDetails.developer);
        store.user.isBizDev = Boolean(authDetails.bizDev);
        if (authDetails.organization) store.user.details.organization = authDetails.organization;
        else console.error('User does not have organization in token!');
        if (authDetails.location) store.user.details.location = authDetails.location;
        else console.error('User does not have location in token!');
      })(action);
      break;
    case SIGN_OUT:
      (() => {
        store.initialized = true;
        store.user.isLoggedIn = false;
        store.user.isAdmin = false;
        store.user.isKiosk = false;
        store.user.isManager = false;
        store.user.isDeveloper = false;
        store.user.authDetails = null;
        store.user.details = init.user.details;
        store.user.readings = [];
      })();
      break;
    case UPDATE_USER_DETAILS:
      (({ payload }) => {
        store.user.details = merge(store.user.details, omit(['organization', 'location'])(payload));
      })(action);
      break;
    case UPDATE_USER_ORGANIZATION:
      (({ payload }) => {
        store.organization = defaults(store.organization)(payload);
      })(action);
      break;
    case UPDATE_USER_READINGS:
      (({ payload }) => {
        store.user.readings = payload;
      })(action);
      break;
    case UPDATE_USER_SURVEYS:
      (({ payload }) => {
        store.user.surveys = payload;
      })(action);
      break;
    case UPDATE_USER_NOTIFICATIONS:
      (({ payload }) => {
        store.user.notifications = payload;
      })(action);
      break;
    case SET_HYDRATED:
      (({ payload }) => {
        store.pendingSubscriptions = { ...store.pendingSubscriptions, [payload]: true };
      })(action);
      break;
    case SET_UNHYDRATED:
      (({ payload }) => {
        const newSubs = { ...store.pendingSubscriptions, [payload]: false };
        store.pendingSubscriptions = newSubs;
      })(action);
      break;
    case SET_CC:
      (({ payload }) => {
        store.user.lastCC = payload;
      })(action);
      break;
    case SET_INITIALIZED:
      (({ payload }) => {
        store.initialized = payload;
      })(action);
      break;
  }
});

export default userReducer;
