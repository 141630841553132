import { lazy } from 'react';
import { OrganizationModules } from 'types/store';

const InvoiceKey = lazy(() => import('containers/DevTools/InvoiceKey'));
const BizDevDashboard = lazy(() => import('containers/BizDevDashboard/BizDevDashboard'));

const bizDevRoutes = (modules: OrganizationModules) => ({
  '/invoice-tool': InvoiceKey,
  '/business-dashboard': BizDevDashboard,
});

export default bizDevRoutes;
