import React, { useState, useRef, useEffect } from 'react';
import { useSpring, animated as a } from 'react-spring';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';

interface Severity {
  severity: 'error' | 'info' | 'success' | 'warning';
}

const useClasses = (theme: Theme) =>
  makeStyles({
    addison_alert: ({ severity }: Severity) => ({
      width: '250px',
      background: theme.palette[severity].main,
      color: theme.palette.common.white,
    }),
    alert_timeout: {
      display: 'block',
      height: '4px',
      width: '250px',
      marginTop: '-4px',
      borderRadius: '4px',
      background: ({ severity }: Severity) => theme.palette[severity].light,
    },
    alert_body: {
      fontWeight: 600,
      fontSize: '1rem',
      color: ({ severity }: Severity) => theme.palette.common.white,
    },
  });

interface AddisonAlertProps {
  severity: 'error' | 'info' | 'success' | 'warning';
  closeText?: string;
  timeout: number;
  onClose: () => void;
  body: string;
}

const shouldTimeout = (timeout: number): boolean => timeout > 500 && timeout !== Infinity;

const AddisonAlert = ({ severity, closeText, onClose, body, timeout }: AddisonAlertProps) => {
  const theme = useTheme();
  const { addison_alert, alert_timeout, alert_body } = useClasses(theme)({ severity });
  const [shouldUnmount, setShouldUnmount] = useState(false);
  const isTransitioning = useRef(false);
  const timeoutStyles = useSpring({
    width: shouldTimeout(timeout) ? '100%' : '0%',
    opacity: shouldTimeout(timeout) ? 0.5 : 0,
    from: {
      width: `0%`,
      opacity: shouldTimeout(timeout) ? 1 : 0,
    },
    config: {
      duration: timeout,
      immediate: !shouldTimeout(timeout),
    },
  });
  useEffect(() => {
    if (shouldTimeout(timeout)) {
      const setTmOot = setTimeout(() => setShouldUnmount(true), timeout);
      return () => clearTimeout(setTmOot);
    }
  }, [timeout]);
  useEffect(() => {
    if (shouldTimeout(timeout) && shouldUnmount && !isTransitioning.current) {
      isTransitioning.current = true;
      onClose();
    }
    return () => setShouldUnmount(false);
  }, [shouldUnmount, onClose, timeout]);
  return (
    <>
      <MuiAlert
        className={addison_alert}
        elevation={6}
        variant="filled"
        severity={severity}
        color={severity}
        onClose={onClose}
        closeText={closeText}
      >
        <div className={alert_body}>{body}</div>
      </MuiAlert>
      {shouldTimeout(timeout) && <a.div className={alert_timeout} style={timeoutStyles} />}
    </>
  );
};

export default AddisonAlert;
