import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import useAuthSync from 'store/subscriptions/useAuthSync.ts';
import useUserSync from 'store/subscriptions/useUserSync.ts';
import useOrgSync from 'store/subscriptions/useOrgSync.ts';
import useReadingsSync from 'store/subscriptions/useReadingsSync.ts';
import useSurveysSync from 'store/subscriptions/useSurveysSync.ts';
import useNotificationSync from 'store/subscriptions/useNotificationSync.ts';
import useModulesSync from 'store/subscriptions/useModulesSync';
import Notifications from 'containers/Notifications/Notifications.tsx';
import Routes from 'containers/Routes/Routes.tsx';
import { ThemeProvider } from '@material-ui/core/styles';
import createCustomTheme from 'theme/global.ts';
import ContextProvider from 'notificationsContext/Context';
import useStoreSync from 'hooks/useStoreSync';

const App = () => {
  useStoreSync();
  useAuthSync();
  useModulesSync();
  useNotificationSync();
  useUserSync();
  useOrgSync();
  useReadingsSync();
  useSurveysSync();

  return (
    <ContextProvider>
      <ThemeProvider theme={createCustomTheme({})}>
        <BrowserRouter>
          <Notifications />
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    </ContextProvider>
  );
};

export default App;
