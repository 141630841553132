import { Subject, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import * as A from 'fp-ts/lib/Array';
import * as E from 'fp-ts/lib/Either';
import * as D from 'io-ts/lib/Decoder';
import { pipe } from 'fp-ts/lib/function';
import { useEffect } from 'react';
import DecodeTemperature from 'utils/DecodeTemperature';
import DecodePulseOx from 'utils/DecodePulseOx';
import DecodeBloodPressure from 'utils/DecodeBloodPressure';
import tapLeft from 'utils/tapLeft';
import useStoreActions from 'hooks/useStoreActions';
import useStoreState from 'hooks/useStoreState';
import useNotifyError from 'hooks/useNotifyError';
import { fireStore as firestore } from 'containers/Login/fireBaseConfig';
import { UserReadings } from 'types/store';
import { updateUserReadings, setHydrated, setUnhydrated } from 'store/model/user/user.types';
import firebase from 'firebase/app';

const DecodeUserReading = D.union(DecodeTemperature, DecodePulseOx, DecodeBloodPressure);

// const daysToMilliseconds = (days: number) => days * 86400000;
// const getStartSearchDate = (daysBack: number) =>
//   new Date(new Date().getTime() - daysToMilliseconds(daysBack));

const midnight = new Date(new Date().setHours(0, 0, 0, 0));

const accessReadings = (obs$: Observer<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>) => (incP: number) => (uid: string) =>
  firestore.collection('readings').orderBy('date').where('date', '>=', midnight).where('userUid', '==', uid).onSnapshot(obs$);

const getReadings = (readings: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>): Array<UserReadings> =>
  pipe(
    readings.docs,
    A.map((reading) => pipe(reading.data(), DecodeUserReading.decode, E.mapLeft(D.draw), tapLeft(console.warn))),
    A.rights
  );

const useReadingsSync = () => {
  const incubationPeriod = useStoreState(({ organization }) => organization.incubationPeriod);
  const userUid = useStoreState(({ user }) => user.details.uid);
  const dispatch = useStoreActions();
  const notifyError = useNotifyError();
  useEffect(() => {
    if (!incubationPeriod || !userUid) return;
    const readingsData$ = new Subject<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>();
    const unsubscribe = accessReadings(readingsData$)(incubationPeriod)(userUid);
    dispatch(setHydrated('readings'));
    readingsData$.pipe(map(getReadings), map(updateUserReadings)).subscribe(
      (action) => {
        dispatch(action);
        dispatch(setUnhydrated('readings'));
      },
      (e) => {
        notifyError(e);
        dispatch(setUnhydrated('readings'));
      }
    );
    return () => {
      unsubscribe();
      readingsData$.unsubscribe();
    };
  }, [incubationPeriod, dispatch, notifyError, userUid]);
};

export default useReadingsSync;
