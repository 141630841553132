import { Notification, StoreAction } from 'types/store';

/* Adds or removes notifications */
export const NOTIF_ADD = 'NOTIF_ADD';
export type AddNotif = StoreAction<typeof NOTIF_ADD, Notification>;
export const addNotif = (notification: Notification): AddNotif => ({
  type: NOTIF_ADD,
  payload: notification,
});
export const NOTIF_REMOVE = 'NOTIF_REMOVE';
export type RemNotif = StoreAction<typeof NOTIF_REMOVE, string>;
export const remNotif = (uid: string): RemNotif => ({
  type: NOTIF_REMOVE,
  payload: uid,
});

/* Handles Errors */
export const NOTIF_ERROR = 'NOTIF_ERROR';
export type AddError = StoreAction<typeof NOTIF_ERROR, { error: string; prodStr?: string }>;
export const addError = (error: string, prodStr?: string): AddError => ({
  type: NOTIF_ERROR,
  payload: {
    error,
    prodStr,
  },
});
