import { SurveyData, WellnessQr, StoreAction } from 'types/store';

type TPayload = { f: number; c: number };
type PPayload = {
  pulseRate: number;
  o2Sat: number;
  perfIndex: number;
};
type BPayload = { systolic: number; diastolic: number; pulseRate: number };

/* Sets thermometer readings */
export const SET_THERM = 'SET_THERM';
export type SetTherm = StoreAction<typeof SET_THERM, TPayload>;
export const setTherm = (f: number, c: number): SetTherm => ({
  type: SET_THERM,
  payload: {
    f,
    c,
  },
});

/* Sets Pulse Ox Readings */
export const SET_PULSEOX = 'SET_PULSEOX';
export type SetPulseOx = StoreAction<typeof SET_PULSEOX, PPayload>;
export const setPulseOx = (pulseRate: number, o2Sat: number, perfIndex: number): SetPulseOx => ({
  type: SET_PULSEOX,
  payload: {
    pulseRate,
    o2Sat,
    perfIndex,
  },
});

/* Sets Blood Pressure Readings */
export const SET_BP = 'SET_BP';
export type SetBp = StoreAction<typeof SET_BP, BPayload>;
export const setBp = (systolic: number, diastolic: number, pulseRate: number): SetBp => ({
  type: SET_BP,
  payload: {
    pulseRate,
    systolic,
    diastolic,
  },
});

/* Sets the Survey */
export const SET_SURVEY = 'SET_SURVEY';
export type SetSurvey = StoreAction<typeof SET_SURVEY, SurveyData>;
export const setSurvey = (surveyData: SurveyData): SetSurvey => ({
  type: SET_SURVEY,
  payload: surveyData,
});

export const SET_WELLNESS_URI = 'SET_WELLNESS_URI';
export type SetWellnessUri = StoreAction<typeof SET_WELLNESS_URI, WellnessQr>;
export const setWellnessUri = (uri: WellnessQr): SetWellnessUri => ({
  type: SET_WELLNESS_URI,
  payload: uri,
});

export const SET_AGREE = 'SET_AGREE';
export type SetAgree = StoreAction<typeof SET_AGREE, boolean>;
export const setAgree = (agree: boolean): SetAgree => ({
  type: SET_AGREE,
  payload: agree,
});
