import { Subject, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import * as A from 'fp-ts/lib/Array';
import * as E from 'fp-ts/lib/Either';
import * as D from 'io-ts/lib/Decoder';
import { pipe } from 'fp-ts/lib/function';
import { useEffect } from 'react';
import firebase from 'firebase/app';
import DecodeSurvey from 'utils/DecodeSurvey';
import tapLeft from 'utils/tapLeft';
import useStoreActions from 'hooks/useStoreActions';
import useStoreState from 'hooks/useStoreState';
import useNotifyError from 'hooks/useNotifyError';
import { fireStore as firestore } from 'containers/Login/fireBaseConfig';
import { SurveyData } from 'types/store';
import { updateUserSurveys, setHydrated, setUnhydrated } from 'store/model/user/user.types';

const midnight = new Date(new Date().setHours(0, 0, 0, 0));

const accessSurveys = (obs$: Observer<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>) => (incP: number) => (uid: string) =>
  firestore.collection('survey').orderBy('date').where('date', '>=', midnight).where('userUid', '==', uid).onSnapshot(obs$);
const getSurveys = (surveys: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>): Array<SurveyData> =>
  pipe(
    surveys.docs,
    A.map((survey: { data: () => any }) => pipe(survey.data(), DecodeSurvey.decode, E.mapLeft(D.draw), tapLeft(console.warn))),
    A.rights
  );

const useSurveysSync = () => {
  const incubationPeriod = useStoreState(({ organization }) => organization.incubationPeriod);
  const userUid = useStoreState(({ user }) => user.details.uid);
  const dispatch = useStoreActions();
  const notifyError = useNotifyError();
  useEffect(() => {
    if (!incubationPeriod || !userUid) return;
    const surveyData$ = new Subject<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>();
    const unsubscribe = accessSurveys(surveyData$)(incubationPeriod)(userUid);
    dispatch(setHydrated('surveys'));
    surveyData$.pipe(map(getSurveys), map(updateUserSurveys)).subscribe(
      (action) => {
        dispatch(action);
        dispatch(setUnhydrated('surveys'));
      },
      (e) => {
        notifyError(e);
        dispatch(setUnhydrated('surveys'));
      }
    );
    return () => {
      unsubscribe();
      surveyData$.unsubscribe();
    };
  }, [incubationPeriod, dispatch, notifyError, userUid]);
};

export default useSurveysSync;
