import action from 'utils/action';
import { SET_LANG, SetLang } from 'store/model/language/language.types';
import i18n from 'i18next';

export type LangActionTypes = SetLang;

const langReducer = action((store, action) => {
  switch (action.type) {
    case SET_LANG:
      (({ payload }) => {
        i18n.changeLanguage(payload);

        store.language = payload;
      })(action);
      break;
  }
});

export default langReducer;
