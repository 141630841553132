import firebase from 'firebase/app';
import * as D from 'io-ts/lib/Decoder';

const FirebaseTimestamp: D.Decoder<unknown, firebase.firestore.Timestamp> = {
  decode: (u: any) =>
    u && typeof u.toDate === 'function'
      ? D.success(u as firebase.firestore.Timestamp)
      : D.failure(u, 'Firestore.Timestamp'),
};

export default FirebaseTimestamp;
