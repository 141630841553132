import React from 'react';
import Badge from '@material-ui/core/Badge';
import { withStyles, createStyles } from '@material-ui/core/styles';

const StyledBadge = withStyles((theme: any) =>
  createStyles({
    badge: {
      right: -1,
      top: 5,
      padding: '0 4px',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
  }),
)(Badge);

const AddisonBadge = ({ children, invisible, badgeContent, variant }: any) => {
  return <StyledBadge {...{ badgeContent, invisible, variant }}>{children}</StyledBadge>;
};

export default AddisonBadge;
