import filter from 'lodash/fp/filter';
import action from 'utils/action';
import { Notification } from 'types/store';
import {
  NOTIF_ADD,
  NOTIF_REMOVE,
  NOTIF_ERROR,
  AddNotif,
  RemNotif,
  AddError,
} from 'store/model/notifications/notif.types';
import formatErrors from 'utils/formatErrors';

export type NotifActionTypes = AddNotif | RemNotif | AddError;

const notifReducer = action((store, action) => {
  switch (action.type) {
    case NOTIF_ADD:
      (({ payload: notif }) => {
        store.notifications.push(notif);
      })(action);
      break;
    case NOTIF_REMOVE:
      (({ payload: uid2 }) => {
        store.notifications = filter<Notification>(({ uid: uid1 }) => uid1 !== uid2)(
          store.notifications,
        );
      })(action);
      break;
    case NOTIF_ERROR:
      (({ payload: error }) => {
        store.errors.push(formatErrors(error));
      })(action);
      break;
  }
});

export default notifReducer;
