import { Subject, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import * as O from 'fp-ts/lib/Option';
import { useEffect } from 'react';
import useStoreActions from 'hooks/useStoreActions';
import useStoreState from 'hooks/useStoreState';
import useNotifyError from 'hooks/useNotifyError';
import { fireStore as firestore } from 'containers/Login/fireBaseConfig';
import { UserDetails } from 'types/store';
import { addError, AddError } from 'store/model/notifications/notif.types';
import { updateUserDetails, UpdateUserDetails, setHydrated, setUnhydrated } from 'store/model/user/user.types';
import firebase from 'firebase/app';

const accessUserDb = (obs$: Observer<firebase.firestore.DocumentData>) => (uid: string) =>
  firestore.collection('users').doc(uid).onSnapshot(obs$);
const getUserData = (userDoc: firebase.firestore.DocumentData): O.Option<UserDetails> => (userDoc.exists ? O.some(userDoc.data()) : O.none);

const useUserSync = () => {
  const userUid = useStoreState(({ user }) => user.details.uid);
  const dispatch = useStoreActions();
  const notifyError = useNotifyError();
  useEffect(() => {
    if (!userUid) return;
    const userData$ = new Subject<firebase.firestore.DocumentData>();
    const unsubscribe = accessUserDb(userData$)(userUid);
    dispatch(setHydrated('user'));
    userData$
      .pipe(
        map(getUserData),
        map(O.fold<UserDetails, AddError | UpdateUserDetails>(() => addError('No user document found in firestore'), updateUserDetails))
      )
      .subscribe(
        (action) => {
          dispatch(action);
          dispatch(setUnhydrated('user'));
        },
        (e) => {
          notifyError(e);
          dispatch(setUnhydrated('user'));
        }
      );
    return () => {
      unsubscribe();
      userData$.unsubscribe();
    };
  }, [userUid, dispatch, notifyError]);
};

export default useUserSync;
