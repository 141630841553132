import cuid from 'cuid';
import { map } from 'rxjs/operators';
import { Epic, ofType, StateObservable } from 'redux-observable';
import { Notification, GlobalStore, StoreError, StoreAction } from 'types/store';
import formatErrors from 'utils/formatErrors';
import { NOTIF_ERROR, addNotif } from 'store/model/notifications/notif.types';
import firebaseErrors from 'utils/firebaseErrors';
import isDevEnv from 'utils/isDevEnv';

/*
 *  Action In: NOTIF_ERROR
 *  Actions Out: NOTIF_ADD
 *
 */
export const addErrorEpic: Epic = (action$, state$: StateObservable<GlobalStore>) =>
  action$.pipe(
    ofType(NOTIF_ERROR),
    map(
      ({
        payload: { error, prodStr },
      }: StoreAction<typeof NOTIF_ERROR, StoreError>): Notification => ({
        severity: 'error',
        body: isDevEnv()
          ? formatErrors(error)
          : prodStr
          ? formatErrors(prodStr)
          : firebaseErrors.default[state$.value.language],
        uid: cuid(),
        timeout: Infinity,
      }),
    ),
    map(addNotif),
  );
