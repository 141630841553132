import { Subject, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import * as O from 'fp-ts/lib/Option';
import * as E from 'fp-ts/lib/Either';
import * as D from 'io-ts/lib/Decoder';
import { flow } from 'fp-ts/lib/function';
import { useEffect } from 'react';
import useStoreActions from 'hooks/useStoreActions';
import useStoreState from 'hooks/useStoreState';
import useNotifyError from 'hooks/useNotifyError';
import DecodeOrg from 'utils/DecodeOrg';
import tapLeft from 'utils/tapLeft';
import { fireStore as firestore } from 'containers/Login/fireBaseConfig';
import { Organization } from 'types/store';
import { addError, AddError } from 'store/model/notifications/notif.types';
import { updateUserOrganization, UpdateUserOrganization, setHydrated, setUnhydrated } from 'store/model/user/user.types';
import firebase from 'firebase/app';

const accessOrgDb = (obs$: Observer<firebase.firestore.DocumentData>) => (org: string) =>
  firestore.collection('organizations').doc(org).onSnapshot(obs$);
const getOrgData = (orgDoc: firebase.firestore.DocumentData): O.Option<unknown> => (orgDoc.exists ? O.some(orgDoc.data()) : O.none);

const useOrgSync = () => {
  const organization = useStoreState(({ user }) => user.details.organization);
  const dispatch = useStoreActions();
  const notifyError = useNotifyError();
  useEffect(() => {
    if (!organization) return;
    const orgData$ = new Subject<firebase.firestore.DocumentData>();
    const unsubscribe = accessOrgDb(orgData$)(organization);
    dispatch(setHydrated('organization-document'));
    orgData$
      .pipe(
        map(getOrgData),
        map(
          flow(
            O.map(DecodeOrg.decode),
            O.map(E.mapLeft(D.draw)),
            O.map(tapLeft(console.warn)),
            O.chain(O.fromEither),
            O.fold<Partial<Organization>, AddError | UpdateUserOrganization>(
              () => addError(`Unknown, or malformed organization: ${organization}`),
              updateUserOrganization
            )
          )
        )
      )
      .subscribe(
        (action) => {
          dispatch(action);
          dispatch(setUnhydrated('organization-document'));
        },
        (e) => {
          notifyError(e);
          dispatch(setUnhydrated('organization-document'));
        }
      );
    return () => {
      unsubscribe();
      orgData$.unsubscribe();
    };
  }, [organization, dispatch, notifyError]);
};

export default useOrgSync;
