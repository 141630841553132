import Firebase from 'firebase/app';
import { UserDetails, UserClaims, Organization, StoreAction, UserReadings, SurveyData, UserNotification } from 'types/store';

/* Sets the user's auth state */
export const SIGN_IN = 'SIGN_IN';
export type UserSignIn = StoreAction<typeof SIGN_IN, UserClaims & Firebase.User>;
export const userSignIn = (authDetails: UserClaims & Firebase.User): UserSignIn => ({
  type: SIGN_IN,
  payload: authDetails,
});
export const SIGN_OUT = 'SIGN_OUT';
export type UserSignOut = StoreAction<typeof SIGN_OUT, undefined>;
export const userSignOut = (): UserSignOut => ({
  type: SIGN_OUT,
  payload: undefined,
});

/* Sets the user's data */
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export type UpdateUserDetails = StoreAction<typeof UPDATE_USER_DETAILS, Partial<UserDetails>>;
export const updateUserDetails = (payload: Partial<UserDetails>): UpdateUserDetails => ({
  type: UPDATE_USER_DETAILS,
  payload,
});

/* Sets the user's organization */
export const UPDATE_USER_ORGANIZATION = 'UPDATE_USER_ORGANIZATION';
export type UpdateUserOrganization = StoreAction<typeof UPDATE_USER_ORGANIZATION, Partial<Organization>>;
export const updateUserOrganization = (payload: Partial<Organization>): UpdateUserOrganization => ({
  type: UPDATE_USER_ORGANIZATION,
  payload,
});

/* Updates the user's readings */
export const UPDATE_USER_READINGS = 'UPDATE_USER_READINGS';
export type UpdateUserReadings = StoreAction<typeof UPDATE_USER_READINGS, Array<UserReadings>>;
export const updateUserReadings = (payload: Array<UserReadings>): UpdateUserReadings => ({
  type: UPDATE_USER_READINGS,
  payload,
});

/* Updates the user's surveys */
export const UPDATE_USER_SURVEYS = 'UPDATE_USER_SURVEYS';
export type UpdateUserSurveys = StoreAction<typeof UPDATE_USER_SURVEYS, Array<SurveyData>>;
export const updateUserSurveys = (payload: Array<SurveyData>): UpdateUserSurveys => ({
  type: UPDATE_USER_SURVEYS,
  payload,
});

/* Updates the user's notifications */
export const UPDATE_USER_NOTIFICATIONS = 'UPDATE_USER_NOTIFICATIONS';
export type UpdateUserNotifications = StoreAction<typeof UPDATE_USER_NOTIFICATIONS, Array<UserNotification>>;
export const updateUserNotifications = (payload: Array<UserNotification>): UpdateUserNotifications => ({
  type: UPDATE_USER_NOTIFICATIONS,
  payload,
});

/*  Sets the indication that all initial asynchronous
 *  computations have settled
 */
export const SET_HYDRATED = 'SET_HYDRATED';
export type SetHydrated = StoreAction<typeof SET_HYDRATED, string>;
export const setHydrated = (payload: string): SetHydrated => ({
  type: SET_HYDRATED,
  payload,
});

/* Sets the indication that initial asynchronous
 * computations require a re-signal
 */
export const SET_UNHYDRATED = 'SET_UNHYDRATED';
export type SetUnhydrated = StoreAction<typeof SET_UNHYDRATED, string>;
export const setUnhydrated = (payload: string): SetUnhydrated => ({
  type: SET_UNHYDRATED,
  payload,
});

/* Sets the user's last used cc email */
export const SET_CC = 'SET_CC';
export type SetCC = StoreAction<typeof SET_CC, string>;
export const setCC = (payload: string): SetCC => ({
  type: SET_CC,
  payload,
});

export const SET_INITIALIZED = 'SET_INITIALIZED';
export type SetInitialized = StoreAction<typeof SET_INITIALIZED, boolean>;
export const setInitialized = (payload: boolean): SetInitialized => ({
  type: SET_INITIALIZED,
  payload,
});
