export interface Action {
  type: 'ADD_NOTIFICATION' | 'REMOVE_NOTIFICATION';
  value?: Record<string, any>;
}

const reducer = (state: Record<string, any>, action: Action) => {
  switch (action.type) {
    case 'REMOVE_NOTIFICATION':
      const newNotifications = state?.notifications?.filter((e: any) => e?.uid !== action.value) || [];
      return { ...state, notifications: newNotifications };

    case 'ADD_NOTIFICATION':
      return { ...state, notifications: [...state?.notifications, action.value] };

    default:
      return state;
  }
};

export default reducer;
