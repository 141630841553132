import * as D from 'io-ts/lib/Decoder';
import { VitalsSpec as VS, Organization } from 'types/store';

const VitalsSpec = <A, B, C>(
  device: D.Decoder<unknown, A>,
  type: D.Decoder<unknown, B>,
  unit: D.Decoder<unknown, C>
): D.Decoder<unknown, VS<A, B, C>> =>
  D.type({
    device,
    type,
    unit,
    max: D.number,
    min: D.number,
    precision: D.number,
    addisonPassWarning: D.boolean,
  });

const temperature_f = VitalsSpec(D.literal('thermometer'), D.literal('temperature'), D.literal('°F'));
const temperature_c = VitalsSpec(D.literal('thermometer'), D.literal('temperature'), D.literal('°C'));
const o2sat_percentage = VitalsSpec(D.literal('pulse-ox'), D.literal('o2Sat'), D.literal('%SpO2'));
const heartrate_bpm = VitalsSpec(D.union(D.literal('pulse-ox'), D.literal('blood-pressure-monitor')), D.literal('heartrate'), D.literal('bpm'));
const perfindex_percentage = VitalsSpec(D.literal('pulse-ox'), D.literal('perfindex'), D.literal('%PI'));
const systolic_mmHg = VitalsSpec(D.literal('blood-pressure-monitor'), D.literal('systolic'), D.literal('mmHg'));
const diastolic_mmHg = VitalsSpec(D.literal('blood-pressure-monitor'), D.literal('diastolic'), D.literal('mmHg'));
const weight_lbs = VitalsSpec(D.literal('weight-scale'), D.literal('weight'), D.literal('lbs'));

const DecodeOrg: D.Decoder<unknown, Partial<Organization>> = D.partial({
  incubationPeriod: D.nullable(D.number),
  vitalsSpec: D.type({
    temperature_f,
    temperature_c,
    o2sat_percentage,
    heartrate_bpm,
    perfindex_percentage,
    systolic_mmHg,
    diastolic_mmHg,
    weight_lbs,
  }),
  modules: D.type({
    'addison-pass': D.boolean,
    'blood-pressure-monitor': D.boolean,
    glucose: D.boolean,
    'pulse-ox': D.boolean,
    spirometer: D.boolean,
    surveys: D.boolean,
    thermometer: D.boolean,
    'weight-scale': D.boolean,
  }),
  requiredDevices: D.type({
    thermometer: D.boolean,
    'pulse-ox': D.boolean,
    'blood-pressure-monitor': D.boolean,
    'weight-scale': D.boolean,
    'covid-19-survey': D.boolean,
  }),
});

export default DecodeOrg;
