import { lazy } from 'react';
import subscriberRoutes from './subscriberRoutes';
import { OrganizationModules } from 'types/store';

const Admin = lazy(() => import('containers/Admin/index'));

const adminRoutes = (modules: OrganizationModules) => ({
  ...subscriberRoutes(modules),
  '/admin': Admin,
});

export default adminRoutes;
