import { mapTo, tap } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import { SET_CC, SIGN_IN } from 'store/model/user/user.types';
import { storageAvailable } from 'store/localStorage';
import isDevEnv from 'utils/isDevEnv';

/*
 *  Action In: SET_CC
 *  Actions Out: UPDATE_LOCAL_STORAGE
 *
 */
export const ccSetEpic: Epic = (action$, store$) =>
  action$.pipe(
    ofType(SET_CC),
    tap(() => {
      if (!storageAvailable('localStorage')) return;
      window.localStorage.setItem(`PI-CC`, store$.value.user.lastCC);
    }),
    mapTo({
      type: 'UPDATE_LOCAL_STORAGE',
    }),
  );

/*
 *  Action In: SIGN_IN
 *  Action Out: UPDATE_LOG_ROCKET
 *
 */
export const logRocketIdentifyEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType(SIGN_IN),
    tap(({ payload: authDetails }) => {
      if (!isDevEnv()) {
        LogRocket.identify(authDetails.uid, {
          name: authDetails.displayName,
          email: authDetails.email,
          admin: authDetails.admin,
          isKiosk: authDetails.isKiosk,
          isManager: authDetails.manager,
          isDeveloper: authDetails.isDeveloper,
        });
        Sentry.setUser({
          id: authDetails.uid,
          name: authDetails.displayName,
          email: authDetails.email,
          admin: authDetails.admin,
          isKiosk: authDetails.isKiosk,
          isManager: authDetails.manager,
          isDeveloper: authDetails.isDeveloper,
        });
      }
    }),
    mapTo({
      type: 'UPDATE_LOGROCKET',
    }),
  );
