import { lazy } from 'react';
import { OrganizationModules } from 'types/store';

const DisplayUserFeedback = lazy(() => import('containers/DisplayUserFeedback/DisplayUserFeedback'));
const DisplayBugReports = lazy(() => import('containers/DisplayBugReports/DisplayBugReports'));
const DevTools = lazy(() => import('containers/DevTools/DevTools'));
const TestNotifications = lazy(() => import('containers/TestNotifications/TestNotifications'));
const AddFlags = lazy(() => import('containers/DevTools/AddFlags'));
const OnboardOrganization = lazy(() => import('containers/DevTools/OnboardOrganization'));
const DevQrPass = lazy(() => import('containers/DevQrPass/DevQrPass'));
const UpdateLocation = lazy(() => import('containers/DevTools/UpdateLocation/UpdateLocation'));
const DeviceDebug = lazy(() => import('containers/DevDeviceDebug/DevDeviceDebug'));
const GetCustomClaims = lazy(() => import('containers/DevTools/GetCustomClaims'));
const CreateDashboardSubscriber = lazy(() => import('containers/DevTools/CreateDashboardSubscriber'));
const RemoveUser = lazy(() => import('containers/DevTools/RemoveUser'));
const CreateSingleUser = lazy(() => import('containers/DevTools/CreateSingleUser'));
const SetCustomClaims = lazy(() => import('containers/DevTools/SetCustomClaims'));
const VerifyAccount = lazy(() => import('containers/DevTools/VerifyAccount'));
const BulkChangeUserLocation = lazy(() => import('containers/DevTools/bulkChangeUserLocation'));
const DisableLocation = lazy(() => import('containers/DevTools/DisableLocation'));
const DisableOrganization = lazy(() => import('containers/DevTools/DisableOrganization'));
const SendPushNotification = lazy(() => import('containers/PushManagement/SendPushNotification'));
const OrganizationStatus = lazy(() => import('containers/DevTools/Audits/OrganizationStatus'));
const DisableOrganizationModules = lazy(() => import('containers/DevTools/DisableOrganizationModules/DisableOrganizationModules'));

const devRoutes = (modules: OrganizationModules) => ({
  '/feedback/view': DisplayUserFeedback,
  '/bug-reporter/view': DisplayBugReports,
  '/test-notifications': TestNotifications,
  '/device-debug': DeviceDebug,
  '/qr-pass': DevQrPass,
  '/functions/flags': AddFlags,
  '/functions/onboard-organization': OnboardOrganization,
  '/functions/get-claims': GetCustomClaims,
  '/remove-user': RemoveUser,
  '/create-user': CreateSingleUser,
  '/create-dashboard-subscriber': CreateDashboardSubscriber,
  '/functions/set-claims': SetCustomClaims,
  '/functions/verify-account': VerifyAccount,
  '/functions/bulk-move-users': BulkChangeUserLocation,
  '/update-location': UpdateLocation,
  '/disable-location': DisableLocation,
  '/disable-organization': DisableOrganization,
  '/push-notification': SendPushNotification,
  '/dev-dashboard': DevTools,
  '/audits/organization-status': OrganizationStatus,
  '/disable-org-modules': DisableOrganizationModules,
});

export default devRoutes;
