import { useEffect } from 'react';
import { Subject, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import firebase from 'firebase/app';

import useStoreActions from 'hooks/useStoreActions';
import useStoreState from 'hooks/useStoreState';
import useNotifyError from 'hooks/useNotifyError';

import { fireStore as firestore } from 'containers/Login/fireBaseConfig';
import { updateUserNotifications, setHydrated, setUnhydrated } from 'store/model/user/user.types';
import { UserNotification } from 'types/store';

const listenNotifications = (obs: Observer<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>, uid: string) =>
  firestore.collection('users').doc(uid).collection('notifications').orderBy('read', 'asc').orderBy('date', 'desc').limit(7).onSnapshot(obs);

const getDocs = (snapshot: any) =>
  snapshot.docs?.map((e: any) => {
    return { ...e.data(), id: e.id } as UserNotification;
  });

const useNotificationSync = () => {
  const userUid = useStoreState(({ user }) => user.details.uid);
  const dispatch = useStoreActions();
  const notifyError = useNotifyError();

  useEffect(() => {
    if (!userUid) return;

    const notificationData = new Subject<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>();

    const unSub = listenNotifications(notificationData, userUid);
    dispatch(setHydrated('notifications'));
    notificationData.pipe(map(getDocs), map(updateUserNotifications)).subscribe(
      (action) => {
        dispatch(action);
        dispatch(setUnhydrated('notifications'));
      },
      (e) => {
        notifyError(e);
        dispatch(setUnhydrated('notifications'));
      }
    );

    return () => {
      unSub();
      notificationData.unsubscribe();
    };
  }, [userUid, dispatch, notifyError]);
};

export default useNotificationSync;
