import React from 'react';
import { animated as a } from 'react-spring';
import { makeStyles } from '@material-ui/core/styles';
import useFade from 'hooks/useFade';
import AddisonLoader from 'components/AddisonLoader/AddisonLoader';

const useClasses = makeStyles({
  loading_page: {
    display: 'flex',
    padding: '18px',
  },
});

const LoadingScreen = () => {
  const { loading_page } = useClasses();
  const fade = useFade(undefined, 1000);
  return (
    <a.div style={fade} className={loading_page}>
      <AddisonLoader />
    </a.div>
  );
};

export default LoadingScreen;
