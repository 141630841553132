import { createGenerateClassName } from '@material-ui/core/styles';

import React from 'react';
import { StylesProvider } from '@material-ui/core/styles';

export const generateClassName = createGenerateClassName({
  productionPrefix: 'pi',
});

export const StylesWrapper = ({
  children,
}: {
  children: React.ReactChildren | React.ReactChild;
}) => <StylesProvider generateClassName={generateClassName}>{children}</StylesProvider>;
