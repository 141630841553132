import { createMuiTheme } from '@material-ui/core/styles';

const createCustomTheme = (theme: any) =>
  createMuiTheme({
    palette: {
      common: {
        black: '#000',
        white: '#f2f2f2',
        grey: '#888888',
        yellow: '#FEEC13',
        red: '#E1627E',
        ...(theme?.common || {}),
      },
      primary: {
        light: '#bfedfc', // Used in email
        main: '#169DCE',
        dark: '#18b0e7',
        grey: '#585858',
        ...(theme?.primary || {}),
      },
      secondary: {
        light: '#0066ff',
        main: '#f2f2f2',
        dark: '#f2f2f2',
        darkBlue: '#0D426A',
        contrastText: '#fff',
        ...(theme?.secondary || {}),
      },
      error: {
        light: '#FCD0D0', // Used for error text
        main: '#f24545', // Used for error notifications
        dark: '#f24545',
        ...(theme?.error || {}),
      },
      success: {
        light: '#DDF9F5',
        main: '#4CBFAC',
        dark: '#0C6761',
        ...(theme?.success || {}),
      },
      warning: {
        light: '#FFF1E2',
        main: '#FE8A13',
        dark: '#6B410C',
        ...(theme?.warning || {}),
      },
      info: {
        light: '#DDF7FF',
        main: '#2BC4F3',
        dark: '#0C3F67',
        ...(theme?.info || {}),
      },
      contrastThreshold: 3,
      blurBackgroundColor: theme?.blurBackgroundColor || '#255380',
      glowBackgroundColor: theme?.glowBackgroundColor || '#ffffff',
    },
    typography: {
      fontFamily: 'Open Sans',
    },
    overrides: {
      // Style sheet name
      MuiButton: {
        // Name of the rule
        text: {
          // Some CSS
          color: '#f2f2f2',
        },
        // hover: {
        //   "&:hover": {
        //     // backgroundColor: 'rgb(7, 177, 77, 0.42)'
        //     boxShadow: '0 0px 15px 1px rgba(255,255,255,0.5)',
        //   }
        // },
      },
      MuiTypography: {
        h1: {
          color: '#f2f2f2',
        },
        h2: {
          color: '#f2f2f2',
        },
        h3: {
          color: '#f2f2f2',
        },
        h4: {
          color: '#f2f2f2',
          fontWeight: 600,
        },
        h5: {
          color: '#f2f2f2',
        },
        h6: {
          color: '#f2f2f2',
        },
        body1: {
          color: '#f2f2f2',
        },
        body2: {
          color: '#f2f2f2',
        },
        caption: {
          color: '#fff',
        },
        button: {
          color: '#00ff00',
        },
      },
      MuiInputAdornment: {
        root: {},
      },
    },
  });

export default createCustomTheme;
