import { useSpring } from 'react-spring';

const useFade = (on: boolean = true, delay?: number) =>
  useSpring({
    opacity: on ? 1 : 0,
    from: {
      opacity: 0,
    },
    delay,
  });

export default useFade;
