import { useCallback } from 'react';
import cuid from 'cuid';
import { useNotifyContext } from 'notificationsContext/Context';

const useNotify = () => {
  const { dispatch } = useNotifyContext();
  return useCallback(
    (severity: 'error' | 'info' | 'success' | 'warning', body: string, timeout: number = 4500) =>
      dispatch({
        type: 'ADD_NOTIFICATION',
        value: {
          uid: cuid(),
          severity,
          timeout,
          body,
        },
      }),
    [dispatch]
  );
};

export default useNotify;
