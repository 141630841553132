import { useCallback } from 'react';
import compose from 'lodash/fp/compose';
import useStoreActions from 'hooks/useStoreActions';
import { addError } from 'store/model/notifications/notif.types';

const useNotifyError = (): ((err: string, prodStr?: string) => void) => {
  const dispatch = useStoreActions();
  return useCallback(compose(dispatch, addError), [dispatch]);
};

export default useNotifyError;
