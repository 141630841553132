import { Reducer } from 'redux';
import { pipe } from 'fp-ts/lib/function';
import user, { UserActionTypes } from 'store/model/user/user.actions';
import notifications, { NotifActionTypes } from 'store/model/notifications/notif.actions';
import language, { LangActionTypes } from 'store/model/language/language.actions';
import readings, { ReadingActionTypes } from 'store/model/readings/readings.actions';
import theme, { ThemeActionTypes } from 'store/model/theme/theme.actions';
import { GlobalStore } from 'types/store';
import initialStore from 'store/init';

export type StoreActionTypes =
  | UserActionTypes
  | NotifActionTypes
  | LangActionTypes
  | ReadingActionTypes
  | ThemeActionTypes;

const reducer: Reducer<GlobalStore, StoreActionTypes> = (store = initialStore, action) =>
  pipe(
    store,
    user(action),
    notifications(action),
    language(action),
    readings(action),
    theme(action),
  );

export default reducer;
